/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Moment from "react-moment";
import Img from "gatsby-image";
import Layout from "../components/blog/Layout";
import SEO from "../components/blog/seo";

import styles from "./blog.module.css";

const BlogIndex = ({ data }) => {
  const posts = data.allStrapiArticle.edges;

  return (
    <Layout>
      <SEO title="Blog - powered by FitLab" />
      <div className={styles.Posts}>
        {posts.map(({ node }) => {
          const title = node.title || node.slug;
          return (
            <article className={styles.Post} key={node.slug}>
              <header>
                <h2 className={styles.PostTitle}>
                  <Link to={`/blog/${node.slug}/`}>{title}</Link>
                </h2>
              </header>
              <div className={styles.Cover}>
                <Img
                  fluid={node.cover.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
              <div className={styles.PostSubTitle}>
                <small className={styles.PostDate}>
                  <Moment format="MMM Do YYYY">{node.date}</Moment>
                </small>
                {node.category && (
                  <>
                    <div className={styles.PostSubTitleSeparator}>/</div>
                    <small className={styles.PostCategory}>
                      {node.category}
                    </small>
                  </>
                )}
              </div>
              <section>
                <p
                  className={styles.PostDescription}
                  dangerouslySetInnerHTML={{
                    __html: node.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          );
        })}
      </div>
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogIndex;

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiArticle(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          cover {
            childImageSharp {
              fluid(maxWidth: 920, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slug
          category
          date
          title
          content
          description
        }
      }
    }
  }
`;
